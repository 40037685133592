import prettyBytes from "pretty-bytes";
import {
  CONSERVATION_ORGANISATIONS,
  DIVE_CENTRES,
  DIVE_OPERATORS,
  DIVE_RESORTS,
  DIVE_SITES,
  EXPLORE,
  LIVEABOARDS,
  TRIPS,
} from "./routes";

/** 20MB */
export const MAX_IMAGE_SIZE = 20 * 1_000_000;
export const MAX_IMAGE_SIZE_ERROR = `Image must be no larger than ${prettyBytes(
  MAX_IMAGE_SIZE
)}`;
export const MAX_UPLOADS = 10;

export const MAX_CONTENT_WIDTH = 1200;

/** 3MB - This is the size limit that the array buffer allows on _api.email-file-upload-error  */
export const MAX_LOG_FILE_SIZE = 3 * 1_000_000;

export const USER_PREFERENCES_KEY = "user-preferences";

export const EXPLORE_PAGES = {
  [EXPLORE]: {
    title: "View all",
    linkText: "View all",
    description:
      "Explore all the dive sites, trips, conservation organisations, centres, resports, liveaboards and operators",
  },
  [DIVE_SITES]: {
    title: "Dive sites",
    linkText: "Sites",
    description: "Explore all the dive sites",
  },
  [TRIPS]: {
    title: "Dive Trips",
    linkText: "Trips",
    description: "Explore all the dive trips",
  },
  [CONSERVATION_ORGANISATIONS]: {
    title: "Conservation organisations",
    linkText: "Conservation",
    description: "Explore all the conservation organisations",
  },
  [DIVE_CENTRES]: {
    title: "Dive Centres",
    linkText: "Centres",
    description: "Explore all the dive centres",
  },
  [DIVE_RESORTS]: {
    title: "Dive Resorts",
    linkText: "Resorts",
    description: "Explore all the dive resorts",
  },
  [LIVEABOARDS]: {
    title: "Liveaboards",
    linkText: "Liveaboards",
    description: "Explore all the Liveaboards",
  },
  [DIVE_OPERATORS]: {
    title: "Dive operators",
    linkText: "Operators",
    description: "Explore all the dive operators",
  },
} as const;

export const ENTITY_TO_ROUTE = {
  [EXPLORE.replace("/", "")]: EXPLORE,
  [DIVE_SITES.replace("/", "")]: DIVE_SITES,
  [TRIPS.replace("/", "")]: TRIPS,
  [CONSERVATION_ORGANISATIONS.replace("/", "")]: CONSERVATION_ORGANISATIONS,
  [DIVE_CENTRES.replace("/", "")]: DIVE_CENTRES,
  [DIVE_RESORTS.replace("/", "")]: DIVE_RESORTS,
  [LIVEABOARDS.replace("/", "")]: LIVEABOARDS,
  [DIVE_OPERATORS.replace("/", "")]: DIVE_OPERATORS,
} as const;

export type ExplorePage = keyof typeof EXPLORE_PAGES;

export type RegionData = {
  region: string;
  region_slug: string;
  /** Total does not include dive logs */
  total_count: number;
  trip_count: number;
  site_count: number;
  log_count: number;
  centre_count: number;
  resort_count: number;
  liveaboard_count: number;
  operator_count: number;
  conservation_count: number;
  dive_log_count: number | null;
  max_temp: number | null;
  min_temp: number | null;
  max_depth: number | null;
  countries: {
    country: string;
    country_slug: string;
    total_count: number;
    trip_count: number;
    site_count: number;
    log_count: number;
    centre_count: number;
    resort_count: number;
    liveaboard_count: number;
    operator_count: number;
    conservation_count: number;
    country_code: string;
    max_temp: number | null;
    min_temp: number | null;
    max_depth: number | null;
    dive_log_count: number | null;
    areas: {
      area: string;
      area_slug: string;
    }[];
  }[];
};
